import numeral from 'numeral'
import Decimal from 'decimal.js'
import { format } from 'date-fns'

const none = (input) => input
const whitespace = (input) => input.trim()
const num = (input) => input.replace(/[^0-9]/g, '')
const noComma = (input) => input.replace(/[^0-9.]/g, '')
const decimal = (input) => {
    const [integer, dec] = input.split('.')
    return input ? `${integer}.${dec || 0}` : '0'
}
const number = (input) => input.replace(/[^0-9.,]/g, '')
const numberWithPrecision = (input) => input.replace(/[^0-9.]/g, '')
const integer = (input) => input.replace(/[^0-9,]/g, '')
const change = (prop, state) => prop !== state
const yes = () => true
const boolean = (input) => !!input

const formatNumber = (input) => (input
    ? numeral(input).format('0.00')
    : { errorExists: true, value: input })
const formatNumberWithComma = (input) => (input || input === 0 ? numeral(input).format('0,0.00') : '')
const formatNumberWithPrecision = (input) => (input || input === 0 ? new Decimal(input.toString()).toFixed(18) : '')
const formatInteger = (input) => (input
    ? numeral(input).format('0')
    : { errorExists: true, value: input })
const formatIntegerWithComma = (input) => (input || input === 0 ? numeral(input).format('0,0') : '')

const email = (input) => {
    if (!input || !input.match(/^.+@.+\..+$/)) {
        return { errorExists: true, value: input }
    }

    return input.trim()
}

const link = (input) => {
    if (!input) {
        return {
            errorExists: true,
            value: input,
        }
    }

    if (input.startsWith('https://')) {
        return input
    }

    if (input.startsWith('http://')) {
        return input.replace('http', 'https')
    }

    return `https://${input}`
}

const dateDisplay = (input) => {
    const validatedDate = new Date(input)

    if (!input || `${validatedDate}` === 'Invalid Date') {
        return { errorExists: true, value: input }
    }

    return format(validatedDate, 'MM/dd/yyyy')
}

const dateString = (input) => {
    const validatedDate = new Date(input)

    if (!input || `${validatedDate}` === 'Invalid Date') {
        return { errorExists: true, value: input }
    }

    return validatedDate.toISOString()
}

const blockchainAddress = (input) => {
    const formatted = input?.match(/^(0x)?[0-9a-fA-F]{40}$/g)

    if (!formatted || formatted.length !== 1) {
        return { errorExists: true, value: input }
    }

    return input.match(/^(0x)?[0-9a-fA-F]{40}$/g)[0]
}

const exists = (input) => input || { errorExists: true, value: input }

const dropdown = (input) => {
    if (input === '' || (!input && input !== 0)) {
        return { errorExists: true, value: input }
    }
    return input
}

// No false support
const dropdownTypeahead = (input) => {
    if (input === '' || (!input?.value && input.value !== 0)) {
        return { errorExists: true, value: input }
    }

    return input
}

const phone = (input) => {
    const formatted = input.replace(/ /g, '').match(/^\+[1-9]\d{1,14}$/)

    return !formatted
        ? { errorExists: true, value: input }
        : input
}

const checkbox = (value) => ({
    errorExists: !value && value !== false,
    value,
})

export {
    none,
    whitespace,
    num,
    noComma,
    decimal,
    number,
    numberWithPrecision,
    integer,
    change,
    yes,
    boolean,
    formatNumber,
    formatNumberWithComma,
    formatNumberWithPrecision,
    formatInteger,
    formatIntegerWithComma,
    email,
    link,
    dateDisplay,
    dateString,
    blockchainAddress,
    exists,
    dropdown,
    dropdownTypeahead,
    phone,
    checkbox,
}
