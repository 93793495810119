import * as React from 'react'
import DatePicker from 'common/DatePicker'
import Dropdown from 'common/Dropdown'
import DropdownTypeahead from 'common/DropdownTypeahead'
import DropdownTypeaheadWithData from 'common/DropdownTypeaheadWithData'
import TextField from 'common/TextField'
import TextareaAutosize from 'common/TextareaAutosize'
import InputCheckbox from 'common/InputCheckbox'
import InputRadio from 'common/InputRadio'
import InputPhone from 'common/InputPhone'

const FormBlockchainAddress = ({ queryOptions, ...props }) => <TextField data-testid='blockchainAddress-component' fullWidth {...props} />

const FormDate = ({ queryOptions, ...props }) => <DatePicker data-testid='date-component' fullWidth {...props} />
const FormDropdown = ({ queryOptions, ...props }) => <Dropdown data-testid='dropdown-component' fullWidth {...props} />
const FormDropdownTypeahead = ({ queryOptions, ...props }) => <DropdownTypeahead data-testid='dropdownTypeahead-component' fullWidth {...props} />
const FormDropdownTypeaheadWithData = (props) => <DropdownTypeaheadWithData data-testid='dropdownTypeahead-component' fullWidth {...props} />
const FormEmail = ({ queryOptions, ...props }) => <TextField data-testid='email-component' fullWidth {...props} />
const FormInteger = ({ queryOptions, ...props }) => <TextField data-testid='integer-component' fullWidth {...props} />
const FormLink = ({ queryOptions, ...props }) => <TextField data-testid='link-component' fullWidth {...props} />
const FormNumber = ({ queryOptions, ...props }) => <TextField data-testid='number-component' fullWidth {...props} />
const FormNumberWithPrecision = ({ queryOptions, ...props }) => <TextField data-testid='number-with-precision-component' fullWidth {...props} />
const FormPassword = ({ queryOptions, ...props }) => (
    <TextField
        data-testid='password-component'
        type='password'
        fullWidth
        {...props}
    />
)
const FormString = ({ queryOptions, ...props }) => <TextField data-testid='string-component' fullWidth {...props} />
const FormTextArea = ({ queryOptions, ...props }) => (
    <TextField
        data-testid='textArea-component'
        components={{
            Input: TextareaAutosize,
        }}
        fullWidth
        {...props}
    />
)

const FormCheckbox = ({ queryOptions, ...props }) => <InputCheckbox data-testid='checkbox-component' fullWidth {...props} />

const FormBoolean = ({ queryOptions, options, ...props }) => {
    const defaultOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ]

    return (
        <InputRadio
            data-testid='boolean-component'
            RadioGroupProps={{
                sx: { display: 'flex', flexDirection: 'row' },
            }}
            fullWidth
            options={defaultOptions}
            {...props}
        />
    )
}

const FormPhone = ({ queryOptions, ...props }) => <InputPhone data-testid='phone-component' fullWidth {...props} />

export {
    FormBlockchainAddress,
    FormDate,
    FormDropdown,
    FormEmail,
    FormInteger,
    FormLink,
    FormNumber,
    FormNumberWithPrecision,
    FormPassword,
    FormString,
    FormTextArea,
    FormCheckbox,
    FormBoolean,
    FormPhone,
}

export default {
    blockchainAddress: FormBlockchainAddress,
    date: FormDate,
    dropdown: FormDropdown,
    dropdownTypeahead: FormDropdownTypeahead,
    dropdownTypeaheadWithData: FormDropdownTypeaheadWithData,
    email: FormEmail,
    integer: FormInteger,
    link: FormLink,
    number: FormNumber,
    numberWithPrecision: FormNumberWithPrecision,
    password: FormPassword,
    string: FormString,
    textArea: FormTextArea,
    checkbox: FormCheckbox,
    boolean: FormBoolean,
    phone: FormPhone,
}
